/***** Properties *****/
/***** Properties End *****/
/*****  Bootstrap Overides *****/
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.container {
  max-width: 880px;
}

.gutter-5 > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

/*****  Global Styles *****/
html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: 'Arvo', serif;
  font-size: 14px;
  color: #777;
  background-color: #f9f9f9;
}

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

h4 {
  color: #777;
}

p {
  font-size: 12px;
}

a {
  color: #01a5a7;
  text-decoration: none;
}

a:hover {
  color: #01bec0;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: 0;
}

textarea {
  color: #777;
}

label {
  margin: 0 0 0 1rem;
  font-size: 12px;
}

label.limit {
  float: right;
  margin: 0 1rem 0 0;
}

input[type=text] {
  -webkit-appearance: none;
  width: 100%;
  font-size: 10px;
  color: #777;
  border: 1px solid #01a5a7;
  border-radius: 10rem;
  padding: 0.5rem 1.5rem;
}

input[type=text]::-webkit-input-placeholder {
  color: #777;
}

input[type=text]:-ms-input-placeholder {
  color: #777;
}

input[type=text]::placeholder {
  color: #777;
}

.search {
  position: relative;
}

.search.navbar-search {
  width: 250px;
  margin-right: 100px;
}

.search input {
  text-transform: capitalize;
}

.search i.fa {
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  color: #777;
}

select {
  -webkit-appearance: none;
  width: 100%;
  font-size: 10px;
  color: #777;
  text-transform: capitalize;
  position: relative;
  border: 1px solid #01a5a7;
  border-radius: 10rem;
  padding: 0.5rem 1.5rem;
  background: transparent;
}

select.comment-sort {
  width: 25%;
  display: none;
}

select.select {
  background-image: linear-gradient(45deg, transparent 50%, #01a5a7 50%), linear-gradient(135deg, #01a5a7 50%, transparent 50%);
  background-position: calc(100% - 20px) 15px, calc(100% - 15px) 15px;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  background-position-y: 13px;
}

.main.stickEmUp {
  padding-top: 96px;
}

.divider {
  width: 100%;
}

.divider.bottom {
  vertical-align: bottom;
}

.divider.top {
  vertical-align: top;
  position: absolute;
  top: -4px;
}

nav.bg-light {
  background-color: #01a5a7 !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  padding: 0;
}

nav.bg-light.stickEmUp {
  position: fixed !important;
  top: 4px;
  width: 100%;
  z-index: 10;
}

nav.bg-light.stickEmUp .im-logo-white {
  display: block;
}

nav.bg-light .build-pyramid-bubble {
  position: absolute;
  right: 25px;
  top: 25px;
  background-color: #ee4d83;
  padding: 1rem 1.35rem;
  border-radius: 2rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

nav.bg-light .build-pyramid-bubble i {
  font-size: 1.5rem;
  color: #fff;
  position: relative;
  top: 2px;
}

nav.bg-light .navbar-nav .nav-item {
  padding-right: 1.75rem;
  position: relative;
}

nav.bg-light .navbar-nav .nav-item:nth-child(1) {
  padding-left: 1.5rem;
}

nav.bg-light .navbar-nav .nav-item .unapproved-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #ee4d83;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
}

nav.bg-light .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
  text-transform: capitalize;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 3px solid transparent;
}

nav.bg-light .navbar-nav .nav-item .nav-link.active, nav.bg-light .navbar-nav .nav-item .nav-link:focus, nav.bg-light .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
  border-color: #fff;
}

.im-logo {
  max-width: 135px;
  padding: 1rem 0 0 1.5rem;
}

.im-logo-white {
  display: none;
  max-width: 120px;
  padding-right: 1rem;
  padding-left: 1.5rem;
}

.bp-logo {
  max-width: 200px;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}

/*****  Button Styles *****/
a.export {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  border: none;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  transition: all 0.35s;
  text-align: center;
  text-decoration: none;
}

a.orange {
  background-color: #f48640 !important;
}

a.orange:hover {
  background-color: #f37728 !important;
}

button {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  border: none;
  background-color: #01a5a7;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  transition: all 0.35s;
  text-align: center;
  text-decoration: none;
  max-height: 2.5rem;
  cursor: pointer;
}

button.orange {
  background-color: #f48640 !important;
}

button.orange:hover {
  background-color: #f37728 !important;
}

button.danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

button.danger:hover {
  background-color: #fff !important;
  color: #dc3545 !important;
}

button.border {
  background-color: #fff;
}

button.border:hover {
  background-color: #f7f7f7 !important;
}

button.solid {
  background-color: #01a5a7;
  color: #fff;
}

button.solid:hover {
  background-color: #fff !important;
  color: #01a5a7 !important;
}

button:disabled {
  border-color: #f7f7f7;
  color: #f7f7f7;
}

button span {
  margin-left: 0.5rem;
}

button.small {
  font-size: 10px;
  padding: 0.15rem 1rem;
}

button.no-border {
  font-size: 12px;
  border: none !important;
  padding: 0.25rem 0.75rem;
  background-color: transparent;
  color: #01a5a7;
}

button.no-border:hover {
  background-color: transparent !important;
}

button:hover:not(:disabled) {
  transition: all 0.5s;
  background: #017374;
}

.footer-copy {
  padding: 3rem 1.5rem;
}

/*****  Multi Select Style *****/
.select2-container-multi:after {
  content: '\25BE';
  position: absolute;
  top: 0.35rem;
  right: 1.15rem;
  color: #777;
}

.select2-container-multi:focus {
  outline: 0;
}

.select2-container-multi .select2-choices {
  height: 31px !important;
  border: 1px solid #01a5a7 !important;
  background-image: none;
  border-radius: 10rem;
  padding-left: 0.25rem;
  box-shadow: none !important;
}

.select2-container-multi .select2-choices:focus {
  outline: none;
}

.select2-container-multi .select2-choices .select2-search-choice {
  font-size: 10px;
  color: #fff;
  line-height: 1rem;
  background-color: #01a5a7;
  background-image: none;
  border: none;
  box-shadow: none;
  border-radius: 1rem;
  padding: 0.25rem 0.85rem;
  margin: 0.35rem 0.1rem 0.35rem 0;
}

.select2-container-multi .select2-choices .select2-search-choice span {
  margin-left: 0.25rem;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close {
  background-image: url("../../assets/img/select2x2.png") !important;
  left: 3px;
  top: 5px;
  background-size: 60px 40px !important;
}

.select2-container-multi .select2-choices .select2-search-field input {
  position: absolute;
  font-family: 'Arvo', serif;
  font-size: 10px;
  color: #777;
  margin: 0;
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
}

.select2-container-multi .select2-choices .select2-search-field input::-webkit-input-placeholder {
  color: #777;
}

.select2-container-multi .select2-choices .select2-search-field input:-ms-input-placeholder {
  color: #777;
}

.select2-container-multi .select2-choices .select2-search-field input::placeholder {
  color: #777;
}

.dropdown-menu.scrollable-menu {
  padding: 1rem;
  width: 300px;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
}

.dropdown-menu.scrollable-menu li {
  cursor: pointer;
  font-size: 1rem;
  padding: 2px 5px;
}

.dropdown-menu.scrollable-menu li:hover {
  background: #01a5a7;
}

.dropdown-menu.scrollable-menu li:hover a {
  color: #fff !important;
}

.dropdown-menu.scrollable-menu li a {
  color: #01a5a7 !important;
}

.select2-drop-active {
  border: 1px solid #01a5a7;
  margin-top: 5px;
}

.select2-drop-active .select2-results .select2-highlighted {
  background-color: #01a5a7;
}

/* Sweet Alert Styles */
.swal2-info {
  color: #f58220 !important;
  border-color: #fac69a !important;
}

.swal2-popup .swal2-styled {
  border-radius: 2rem !important;
}

.swal2-popup .swal2-styled.swal2-cancel {
  background-color: #919191 !important;
}

.swal2-popup .swal2-styled.swal2-cancel:hover {
  background-color: #777 !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #01a5a7 !important;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
  background-color: #017374 !important;
}

#loading-bar {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 10;
}

#loading-bar .bar {
  background: #ee5989;
  z-index: 11;
}

#loading-bar .bar {
  height: 3px;
}

#loading-bar-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
}

#loading-bar-spinner .spinner-icon {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border-width: 5px;
  top: 50%;
  left: 50%;
  border-top-color: #ee5989;
  border-left-color: #ee5989;
}


/***** Properties *****/
/***** Properties End *****/
button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: 0;
}

.wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}

.form-login,
.form-reset,
.form-password {
  max-width: 420px;
  padding: 3.5rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1rem;
}

.form-login .logo,
.form-reset .logo,
.form-password .logo {
  width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.form-login .form-heading,
.form-reset .form-heading,
.form-password .form-heading {
  text-align: center;
  margin-bottom: 3.5rem;
}

.form-login .checkbox,
.form-reset .checkbox,
.form-password .checkbox {
  margin-bottom: 30px;
  font-weight: normal;
}

.form-login .form-control,
.form-reset .form-control,
.form-password .form-control {
  position: relative;
  height: auto;
}

.form-login .form-control:focus,
.form-reset .form-control:focus,
.form-password .form-control:focus {
  z-index: 2;
}

.form-login label,
.form-reset label,
.form-password label {
  width: 35%;
}

.form-login input,
.form-reset input,
.form-password input {
  width: 100%;
  font-size: 10px;
  color: #01a5a7;
  border: 1px solid #01a5a7;
  border-radius: 10rem;
  padding: 0.5rem 1.5rem;
}

.form-login input::-webkit-input-placeholder,
.form-reset input::-webkit-input-placeholder,
.form-password input::-webkit-input-placeholder {
  color: #01a5a7;
}

.form-login input:-ms-input-placeholder,
.form-reset input:-ms-input-placeholder,
.form-password input:-ms-input-placeholder {
  color: #01a5a7;
}

.form-login input::placeholder,
.form-reset input::placeholder,
.form-password input::placeholder {
  color: #01a5a7;
}

.form-login input[type=email],
.form-login input[type=password],
.form-reset input[type=email],
.form-reset input[type=password],
.form-password input[type=email],
.form-password input[type=password] {
  margin-bottom: 0.75rem;
}

.form-login button[type=submit],
.form-reset button[type=submit],
.form-password button[type=submit] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  background-color: #01a5a7;
  border: none;
  border-radius: 2rem;
}


/***** Properties *****/
/***** Properties End *****/
.alert.alert-info.pyramid-notice {
  border: none;
  border-radius: 20px;
  color: #fff;
  background-color: #f48640;
  font-size: 12px;
}

.alert.alert-info.pyramid-notice .close {
  color: #fff;
  text-shadow: none;
  font-size: 16px;
  color: auto;
  opacity: 1;
}

.scale {
  -webkit-transform: scale(0.5) translate(-35%);
  transform: scale(0.5) translate(-35%);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

section.pyramid-section,
section.pyramids-filter-section,
section.build-pyramid-section {
  border-radius: 1.5rem;
  border-top: 1rem solid;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  cursor: pointer;
}

section.pyramid-section.no-border,
section.pyramids-filter-section.no-border,
section.build-pyramid-section.no-border {
  border: none;
}

section.pyramid-section.filter,
section.pyramids-filter-section.filter,
section.build-pyramid-section.filter {
  border: none;
}

section.pyramid-section .filter-bubble,
section.pyramids-filter-section .filter-bubble,
section.build-pyramid-section .filter-bubble {
  transition: all 0.35s;
  opacity: 0;
}

section.pyramid-section.stickEmUp .filter-bubble,
section.pyramids-filter-section.stickEmUp .filter-bubble,
section.build-pyramid-section.stickEmUp .filter-bubble {
  opacity: 1;
}

section.pyramid-section .message,
section.pyramids-filter-section .message,
section.build-pyramid-section .message {
  color: #ee4d83;
  text-transform: uppercase;
}

section.pyramid-section .pyramid-actions,
section.pyramids-filter-section .pyramid-actions,
section.build-pyramid-section .pyramid-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

section.pyramid-section .pyramid-actions button,
section.pyramids-filter-section .pyramid-actions button,
section.build-pyramid-section .pyramid-actions button {
  margin-left: 0.25rem;
}

section.pyramid-section.pink,
section.pyramids-filter-section.pink,
section.build-pyramid-section.pink {
  border-color: #ee4d83;
}

section.pyramid-section.green,
section.pyramids-filter-section.green,
section.build-pyramid-section.green {
  border-color: #00ab67;
}

section.pyramid-section.blue,
section.pyramids-filter-section.blue,
section.build-pyramid-section.blue {
  border-color: #5296c4;
}

section.pyramid-section.orange,
section.pyramids-filter-section.orange,
section.build-pyramid-section.orange {
  border-color: #f48640;
}

section.pyramid-section .pyramid-info,
section.pyramids-filter-section .pyramid-info,
section.build-pyramid-section .pyramid-info {
  display: none;
}

section.pyramid-section button.build-pyramid,
section.pyramids-filter-section button.build-pyramid,
section.build-pyramid-section button.build-pyramid {
  background-color: #ee4d83;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
}

section.pyramid-section button.build-pyramid.cancel,
section.pyramids-filter-section button.build-pyramid.cancel,
section.build-pyramid-section button.build-pyramid.cancel {
  background-color: #777;
}

section.pyramid-section button.build-pyramid.cancel:hover,
section.pyramids-filter-section button.build-pyramid.cancel:hover,
section.build-pyramid-section button.build-pyramid.cancel:hover {
  background-color: #5e5e5e;
}

section.pyramid-section button.build-pyramid:hover,
section.pyramids-filter-section button.build-pyramid:hover,
section.build-pyramid-section button.build-pyramid:hover {
  background-color: #ea1e63;
}

.build-pyramid-success .swal2-success {
  display: none !important;
}

.build-pyramid-success .build-pyramid-smiley {
  opacity: 0.65;
}

.pyramids-container {
  margin: auto;
}

.pyramids-container .top-pyramid {
  width: 0;
  height: 0;
  border-left: 195px solid transparent;
  border-right: 195px solid transparent;
  border-bottom: 295px solid #f48640;
  position: relative;
  margin-left: 200px;
}

.pyramids-container .top-pyramid .text {
  position: absolute;
  width: 195px;
  left: -98px;
  text-align: center;
  margin-top: 165px;
  color: #FFF;
  font-size: 14px;
}

.pyramids-container .center-pyramid {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  position: absolute;
  margin-left: 315px;
  /* background: #FFF; */
  top: 345px;
  z-index: 8;
}

.pyramids-container .center-pyramid .text {
  position: absolute;
  width: 165px;
  text-align: center;
  color: #929292;
  font-size: 14px;
  height: 155px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pyramids-container .left-pyramid {
  width: 0;
  height: 0;
  border-left: 195px solid transparent;
  border-right: 195px solid transparent;
  border-bottom: 295px solid #ee5989;
  position: relative;
  margin: 0 auto;
  margin-left: 5px;
  float: left;
}

.pyramids-container .left-pyramid .text {
  position: absolute;
  width: 195px;
  left: -98px;
  text-align: center;
  margin-top: 165px;
  color: #FFF;
  font-size: 14px;
}

.pyramids-container .right-pyramid {
  width: 0;
  height: 0;
  border-left: 195px solid transparent;
  border-right: 195px solid transparent;
  border-bottom: 295px solid #9cca56;
  position: relative;
  margin: 0 auto;
  margin-left: 395px;
}

.pyramids-container .right-pyramid .text {
  position: absolute;
  width: 195px;
  left: -98px;
  text-align: center;
  margin-top: 165px;
  color: #FFF;
  font-size: 14px;
}

.pyramid-details {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 285px;
}

.pyramid-details p {
  color: #ee4d83;
  text-transform: capitalize;
}

.pyramid-details p small {
  color: #777;
  display: block;
  text-transform: capitalize;
}

textarea.build {
  -webkit-appearance: none;
  width: 100%;
  min-height: 150px;
  font-size: 10px;
  color: #777;
  border: 1px solid #01a5a7;
  border-radius: 2rem;
  padding: 1.5rem 1.5rem;
}

textarea.build::-webkit-input-placeholder {
  color: #777;
}

textarea.build:-ms-input-placeholder {
  color: #777;
}

textarea.build::placeholder {
  color: #777;
}

.build-label-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.build-orange {
  color: #f48640;
}

.build-orange.build {
  color: #f48640;
  border-color: #f48640;
}

.build-orange.build::-webkit-input-placeholder {
  color: #f48640;
}

.build-orange.build:-ms-input-placeholder {
  color: #f48640;
}

.build-orange.build::placeholder {
  color: #f48640;
}

.build-light-green {
  color: #9cca56;
}

.build-light-green.build {
  color: #9cca56;
  border-color: #9cca56;
}

.build-light-green.build::-webkit-input-placeholder {
  color: #9cca56;
}

.build-light-green.build:-ms-input-placeholder {
  color: #9cca56;
}

.build-light-green.build::placeholder {
  color: #9cca56;
}

.build-pink {
  color: #ee4d83;
}

.build-pink.build {
  color: #ee4d83;
  border-color: #ee4d83;
}

.build-pink.build::-webkit-input-placeholder {
  color: #ee4d83;
}

.build-pink.build:-ms-input-placeholder {
  color: #ee4d83;
}

.build-pink.build::placeholder {
  color: #ee4d83;
}

/***** Google folder and file styles *****/
.drive-folder-container {
  background: #f9f9f9;
  padding: 20px !important;
  border-radius: 10px;
}

.drive-folder-container .file-view {
  display: inline-block;
  width: 33.3333%;
  margin: 0;
  padding-bottom: 30px;
  text-align: center;
}

.drive-folder-container .file-view span {
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.drive-folder-container .file-view a img {
  max-height: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/*****  Comment Styles *****/
.comment-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.comment-filter button {
  margin-left: 0.5rem;
}

.ethos-container,
.authors-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  /* margin-top: 20px !important; */
  padding: 5px;
}

.ethos-container .circle,
.authors-container .circle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
}

.filter-bubble {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  background-color: #ee4d83;
  border-radius: 1rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

section.comments {
  display: none;
}

section.comments .comment {
  padding: 2rem;
  border: 1px solid #777;
  border-radius: 2rem;
  margin-top: 1.5rem;
}

section.comments .comment.reply {
  width: 90%;
  border-color: #ee4d83;
  padding: 1rem 2rem;
  margin-top: 0.5rem;
  margin-left: 10%;
}

section.comments .comment .comment-info {
  font-size: 12px;
}

section.comments .comment .comment-info strong {
  margin-left: 0.5rem;
}

section.comments .comment .comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

section.comments .add-comment,
section.comments .edit-comment,
section.comments .edit-reply {
  width: 100%;
  padding: 1rem 2rem;
  margin: 1rem auto;
  border: 1px solid #777;
  border-radius: 2rem;
}

section.comments .edit-comment {
  border-color: #01a5a7;
}

section.comments .comment-reply {
  display: none;
}


/***** Properties *****/
/***** Properties End *****/
section.ethos-filter-section {
  border-radius: 1.5rem;
  padding: 2rem;
  margin-bottom: 3rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

section.ethos-filter-section hr {
  margin-bottom: 2rem;
}

section.ethos-filter-section .filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 95%;
  margin: auto;
}

section.ethos-filter-section .filters .ethos-filter {
  width: 140px;
  height: 140px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
}

section.ethos-filter-section .filters .ethos-filter p {
  width: 100%;
  line-height: 1rem;
}

section.ethos-filter-section .filters .ethos-filter.active p {
  color: #fff !important;
}

section.ethos-filter-section .filters .ethos-filter.show-all {
  border-color: #878787;
}

section.ethos-filter-section .filters .ethos-filter.show-all p {
  color: #878787;
}

section.ethos-filter-section .filters .ethos-filter.show-all.active {
  background-color: #878787;
}

section.ethos-filter-section .filters .ethos-filter.pink {
  border-color: #ee4d83;
}

section.ethos-filter-section .filters .ethos-filter.pink p {
  color: #ee4d83;
}

section.ethos-filter-section .filters .ethos-filter.pink.active {
  background-color: #ee4d83;
}

section.ethos-filter-section .filters .ethos-filter.green {
  border-color: #00ab67;
}

section.ethos-filter-section .filters .ethos-filter.green p {
  color: #00ab67;
}

section.ethos-filter-section .filters .ethos-filter.green.active {
  background-color: #00ab67;
}

section.ethos-filter-section .filters .ethos-filter.orange {
  border-color: #f48640;
}

section.ethos-filter-section .filters .ethos-filter.orange p {
  color: #f48640;
}

section.ethos-filter-section .filters .ethos-filter.orange.active {
  background-color: #f48640;
}

section.ethos-filter-section .filters .ethos-filter.blue {
  border-color: #5296c4;
}

section.ethos-filter-section .filters .ethos-filter.blue p {
  color: #5296c4;
}

section.ethos-filter-section .filters .ethos-filter.blue.active {
  background-color: #5296c4;
}

section.ethos-section,
section.ethos-edit-section {
  border-radius: 1.5rem;
  border-top: 1rem solid;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

section.ethos-section .ethos-quote,
section.ethos-edit-section .ethos-quote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

section.ethos-section .ethos-quote .ethos-quote-mark,
section.ethos-edit-section .ethos-quote .ethos-quote-mark {
  font-size: 2rem;
  line-height: 2.5rem;
  padding-right: 0.25rem;
}

section.ethos-edit-section {
  border-color: #ee4d83;
}

section.ethos-edit-section .ethos-title {
  display: inline-block;
  width: 50%;
  margin: 0 !important;
}

section.ethos-edit-section .limit.ethos {
  width: 45%;
  text-align: right;
  float: none;
}

section.ethos-edit-section .ethos-color-container,
section.ethos-edit-section .ethos-result,
section.ethos-edit-section .ethos-quote {
  padding: 0.5rem 1rem;
  border: 1px solid #01a5a7;
  border-radius: 2rem;
  font-size: 10px;
  width: 80%;
  display: inline-block;
}

section.ethos-edit-section .ethos-color-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding: 0.35rem 1rem;
  cursor: pointer;
}

section.ethos-edit-section .ethos-color-container hr.ethos {
  border: none;
  height: 1px;
}

section.ethos-edit-section .ethos-color-container p {
  margin: 0;
  font-weight: bold;
  margin-top: 0.15rem;
}

section.ethos-edit-section .ethos-color-container .circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #616161;
}

section.ethos-edit-section .ethos-color-container .circle.selected {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.75);
}

section.ethos-edit-section .ethos-color-container .circle.pink {
  background-color: #ef4d84;
}

section.ethos-edit-section .ethos-color-container .circle.orange {
  background-color: #f5821f;
}

section.ethos-edit-section .ethos-color-container .circle.purple {
  background-color: #6A3074;
}

section.ethos-edit-section .ethos-color-container .circle.light-green {
  background-color: #99ca42;
}

section.ethos-edit-section .ethos-color-container .circle.yellow {
  background-color: #FFCB05;
}

section.ethos-edit-section .ethos-color-container .circle.green {
  background-color: #00AB67;
}

section.ethos-edit-section .ethos-color-container .circle.turqoise {
  background-color: #12b3a5;
}

section.ethos-edit-section .ethos-color-container .circle.blue {
  background-color: #489FD8;
}

section.ethos-edit-section .ethos-quote-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

section.ethos-edit-section .ethos-quote {
  width: 82.5%;
}

section.ethos-edit-section .ethos-result-delete,
section.ethos-edit-section .ethos-quote-delete {
  border: none;
  border-radius: 1.25rem;
  padding: 0.5rem 0.85rem;
  margin-left: 1rem;
}

section.ethos-edit-section .ethos-result-delete i,
section.ethos-edit-section .ethos-quote-delete i {
  color: #fff;
}

section.ethos-edit-section .ethos-quote-delete {
  margin-left: 3.25rem;
}

section.ethos-edit-section button:hover {
  background-color: transparent;
}

section.ethos-edit-section button.edit-ethos {
  background-color: #ee4d83;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
}

section.ethos-edit-section button.edit-ethos.cancel {
  background-color: #616161;
}

section.ethos-edit-section button.edit-ethos.cancel:hover {
  background-color: #484848;
}

section.ethos-edit-section button.edit-ethos:hover {
  background-color: #ea1e63;
}


/***** Properties *****/
/***** Properties End *****/
section.super-admin-section {
  border-radius: 1.5rem;
  border-top: 0.5rem solid;
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

section.super-admin-section.pink {
  border-color: #ee4d83;
}

section.super-admin-section h4 {
  color: #ee4d83;
  text-transform: uppercase;
}

button.create-category,
button.create-theme {
  margin-top: 1.25rem;
}

.category-container .category,
.category-container .theme,
.theme-container .category,
.theme-container .theme {
  width: 47.5%;
  display: inline-block;
  font-size: 14px;
  color: #616161;
  text-align: center;
  border: 1px solid #616161;
  border-radius: 10rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.user-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

section.user-brands-section {
  display: none;
  padding: 4rem;
}

section.user-brands-section label.user-brands {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

section.user-brands-section label.user-brands input[type=checkbox] {
  margin-left: -2rem;
  margin-right: 1rem;
}

